<template>
  <div class="home">
    <el-drawer
      :title="`${formType === 1 ? '编辑' : '新增'}数据`"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      :before-close="handleBeforeClose"
      size="55%"
    >
      <div>
        <div style="height:calc(100vh - 200px);overflow-y:auto">
          <el-form :model="form" size="small" label-width="80px">
            <el-form-item label="类型" :label-width="formLabelWidth">
              <!-- <el-input v-model="" autocomplete="off" /> -->
              <el-select v-model="form.type" placeholder="请选择类型" style="width:100%">
                <el-option
                  v-for="item in options"
                  :key="item.type"
                  :label="item.title"
                  :value="item.type"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="form.type !== 6 && form.type !== 7" label="标题" :label-width="formLabelWidth">
              <el-input v-model="form.title" autocomplete="off" placeholder="请输入标题" />
            </el-form-item>
            <el-form-item v-if="form.type !== 6 && form.type !== 7" label="副标题" :label-width="formLabelWidth">
              <el-input v-model="form.subTitle" autocomplete="off" />
            </el-form-item>
            <!-- <el-form-item label="详细信息" :label-width="formLabelWidth">
          <el-input v-model="form.detail" autocomplete="off" type="textarea" />

            </el-form-item>-->

            <el-form-item v-if="form.type !== 6 && form.type !== 7" label="主图" :label-width="formLabelWidth">
              <el-upload
                class="avatar-uploader"
                action="/api/hnhz/file/upload"
                :headers="{ token: token }"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
            <el-form-item v-if="form.type === 3" label="照片墙" :label-width="formLabelWidth">
              <br>
              <el-upload
                class="upload-demo"
                action="/api/hnhz/file/upload"
                :headers="{ token: token }"
                :file-list="uploadAttachments"
                :on-success="handleUploadSuccess"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="排序" :label-width="formLabelWidth">
              <el-input
                v-model="form.sort"
                autocomplete="off"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
              />
            </el-form-item>

            <el-divider v-if="form.type !== 3" content-position="left">详情信息</el-divider>
            <quill-editor
              v-if="form.type !== 3"
              ref="myQuillEditor"
              v-model="form.detail"
              :options="editorOption"
              style="height:500px"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" style="display: flex;margin-top: 10px;">
          <el-button size="small" style="margin-left: auto; margin-right: 30px;" @click="handleBeforeClose">取 消</el-button>
          <el-button type="primary" :loading="subLoading" size="small" style="margin-right: 50px;" @click="handleSubmit()">确 定</el-button>
        </div>
      </div>
    </el-drawer>
    <div class="table">
      <div class="header">
        <el-input
          v-model="query.name"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width:174px"
          size="mini"
        />
        <el-select
          v-model="query.type"
          placeholder="请选择类型"
          size="mini"
          style="margin-left:10px"
          @change="handleTypeChange"
        >
          <el-option
            v-for="item in options"
            :key="item.type"
            :label="item.title"
            :value="item.type"
          />
        </el-select>

        <el-button type="primary" size="mini" @click="gettableData">查询</el-button>
        <div style="float:right;">
          <el-button type="success" size="mini" @click="handleEdit()">新增</el-button>
          <el-button type="danger" size="mini" @click="BatchhandleDelet()">批量删除</el-button>
        </div>
      </div>
      <div>
        <el-table :data="tableData" stripe fit @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="title" label="标题" />
          <el-table-column prop="subTitle" label="副标题" />
          <!-- <el-table-column prop="detail" label="详细信息" width="180" :show-overflow-tooltip="true" /> -->
          <el-table-column prop="imgUrl" label="主图片路径" width="180">
            <template slot-scope="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column prop="images" label="附件">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.images && scope.row.images.length > 0"
                style="width: 50px; height: 50px"
                :src="scope.row.images[0].imgUrl"
                :preview-src-list="getImgUrls(scope.row.images)"
              />
            </template>
          </el-table-column>-->
          <el-table-column prop="sort" label="排序" />
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">{{ optionsObj[scope.row.type] }}</template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click="handleEdit(scope.row)"
              >编辑</el-button>
              <el-button type="text" size="small" @click="handleDelet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="block">
          <el-pagination
            :current-page="query.pageNum"
            :page-sizes="[5, 10, 20, 30, 40]"
            :page-size="query.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            size="small"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'

Quill.register('modules/ImageExtend', ImageExtend)
import {
  getDatamanagement,
  getSubmit,
  handlDel
} from '../../api/admin/dataManage'
import { getPare } from '../../api/admin/homePage'
export default {
  components: { quillEditor },
  data() {
    return {
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: 'file',
            action: '/api/hnhz/file/upload',
            headers: (xhr) => {
              xhr.setRequestHeader('token', localStorage.getItem('token'))
            },
            response: (res) => {
              return res.data.filePath
            }
          },
          toolbar: {
            container: container,
            handlers: {
              'image': function() {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      uploadAttachments: [],
      tableData: [],
      optionsObj: {},
      query: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        type: 2
      },
      total: 0,
      formType: 0, // 0新增 1编辑
      form: {
        title: '',
        subTitle: '',
        detail: '',
        imgUrl: '',
        sort: '',
        type: '',
        images: []
      },
      dialogFormVisible: false,
      subLoading: false,
      ids: [],
      imageUrl: '',
      token: '',
      options: []
    }
  },
  created() {
    this.token = localStorage.getItem('token')
    this.handeltype()
    this.gettableData()
  },
  methods: {
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    gettableData() {
      getDatamanagement(this.query).then(res => {
        console.log('res', res)
        this.tableData = res.data.items
        this.total = res.data.total
      })
    },
    handleTypeChange() {
      this.query.pageNum = 1
      this.gettableData()
    },
    // 分页
    handleSizeChange(e) {
      console.log('handleSizeChange', e)
      this.query.pageSize = e
      this.gettableData()
    },
    handleCurrentChange(e) {
      console.log('handleCurrentChange', e)
      this.query.pageNum = e
      this.gettableData()
    },
    // 编辑
    handleEdit(row) {
      // 0新增 1编辑
      this.formType = row ? 1 : 0
      console.log('handleEdit', row)
      if (row) {
        this.form = JSON.parse(JSON.stringify(row))
        if (!this.form.images) {
          this.form.images = []
        } else {
          this.uploadAttachments = this.form.images.map(o => {
            return {
              name: o.title,
              imgUrl: o.imgUrl
            }
          })
        }
      }
      this.dialogFormVisible = true
    },
    // 点击取消，弹窗关闭
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    },
    // handleClose(done) {
    //   this.$confirm('确认关闭？')
    //     .then(_ => {
    //       done()
    //     })
    //     .catch(_ => {})
    // },
    // 提交
    handleSubmit() {
      this.subLoading = true
      getSubmit(this.form)
        .then(res => {
          this.subLoading = false
          if (this.formType === 0) {
            this.query.pageNum = 1
          }
          this.handleClose()
          this.gettableData()
        })
        .catch(e => {
          this.subLoading = false
        })
    },
    // 选取某一行数据
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val)
      this.ids = val.map(o => o.id)
      console.log('ids', this.ids)
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log('handleAvatarSuccess', res, file)
      this.form.imgUrl = res.data.filePath
      // this.imageUrl = URL.createObjectURL(file.raw)
    },
    handleUploadSuccess(res, file, fileList) {
      console.log('handleUploadSuccess', res, file, fileList)
      file.imgUrl = res.data.filePath
      this.form.images.push({
        title: file.name,
        imgUrl: res.data.filePath
      })
      console.log('images', this.form.images)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('handleRemove', file, fileList)
      const i = this.form.images.findIndex(o => o.imgUrl === file.imgUrl)
      this.form.images.splice(i, 1)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return isJPG && isLt2M
      return isJPG
    },
    // 删除
    handleDelet(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel([row.id]).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.gettableData()
          })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 批量删除
    BatchhandleDelet() {
      if (this.ids.length <= 0) {
        this.$notify({
          title: '警告',
          message: '请选择要删除的行',
          duration: 1500,
          type: 'warning'
        })
        return
      }
      this.newsLoading = true
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel(this.ids).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.gettableData()
          })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 获取类型
    handeltype() {
      getPare().then(res => {
        console.log('getPare', res)
        this.options = res.data.items
        // const optionsObj = {}
        this.options.map(o => {
          this.optionsObj[o.type] = o.title
        })
        console.log('optionsObj', this.optionsObj)
        this.value = this.optionsObj
      })
    },
    getImgUrls(images) {
      return images.map(o => o.imgUrl)
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后未保存的数据将会丢失，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.handleClose()
        })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item__content {
    width: 300px;
  }
}
</style>

<style lang="scss">
.table {
  border: 1px solid #ddd;
  .header {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    .el-button {
      margin-left: 10px;
    }
  }
}
/* .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
} */
.el-pagination {
  margin: 10px;
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-dialog {
  // overflow: scroll;
  //隐藏侧边默认滚动条 （达不到效果可以自己试着微调） 如解决不了可尝试用 `margin-right -60px`
  // height: 70%;
}
</style>
