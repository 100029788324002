import request from '../../utils/request'
// 数据管理
export function getDatamanagement(data = {}) {
  return request({
    url: `/website/subitem/list`,
    method: 'get',
    params: {
      limit: data.pageSize,
      page: data.pageNum,
      name: data.name,
      type: data.type
    }
  })
}
// 修改 提交
export function getSubmit(data = {}) {
  return request({
    url: data.id ? '/website/subitem/update' : '/website/subitem/save',
    method: data.id ? 'put' : 'post',
    data
  })
}
// 文件上传接口
export function fileUpload(data = {}) {
  return request({
    url: `/hnhz/file/upload`,
    method: 'get',
    data
  })
}
// 删除
export function handlDel(data = []) {
  return request({
    url: '/website/subitem/delete',
    method: 'DELETE',
    data
  })
}
